<template>
  <div class="term-config">
    <div class="list-content" v-loading="loading">
      <div v-if="config" style="min-height: 400px;">
        <div class="config-item">
          <div class="config-title">届（数）配置</div>
          <div class="config-content">
            <el-form class="small-form" size="small" label-width="70px" label-position="left">
              <el-form-item label="本届为第">
                <el-input style="width: 86px; " type="number" v-model="config.name" :min="1"
                          @blur="handleMinusTerm" />
                <span class="suffix">届</span>
              </el-form-item>
              <date-period-picker label="周期时间" :show-btn="false" start-prop="start_time"
                                  :start-time.sync="config.start_time" end-prop="end_time" :end-time.sync="config.end_time">
              </date-period-picker>
            </el-form>
          </div>
        </div>
      <!--      <div class="config-item" v-if="config2">-->
      <!--        <div class="config-title">会费规则</div>-->
      <!--        <div class="config-content">-->
      <!--          <el-form class="small-form" size="small" label-width="70px" label-position="left">-->
      <!--            <el-form-item label="缴费期限">-->
      <!--              <el-input style="width: 86px; " type="number" v-model="config2.fee_deadline" :min="1" @blur="handleMinus"></el-input>-->
      <!--              <span class="suffix">天</span>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="中途入会">-->
      <!--              <el-radio-group v-model="payRule" class="flex-col">-->
      <!--                <el-radio :label="0">需缴纳全额会费</el-radio>-->
      <!--                <el-radio :label="1">按年计算补剩余时间(不足一年的按一年算)</el-radio>-->
      <!--                <el-radio :label="2">按月计算补剩余时间(不足一个月的按一个月算)</el-radio>-->
      <!--              </el-radio-group>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item label="会费说明">-->
      <!--              <el-input type="textarea" v-model="config2.member_state" placeholder="请填写说明"></el-input>-->
      <!--            </el-form-item>-->
      <!--          </el-form>-->
      <!--        </div>-->
      <!--      </div>-->
      </div>
    </div>
    <fixed-action-bar>
      <el-button type="primary" :loading="loading" @click="saveConfig">保存</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
import FixedActionBar from "../../../../base/layout/FixedActionBar";
import {
  getMemberConfig,
  getTermConfig,
  saveMemberConfig,
  saveTermConfig,
} from "../../api/other-config";
import DatePeriodPicker from "../../../../base/components/Form/DatePeriodPicker";
export default {
  components: { DatePeriodPicker, FixedActionBar },
  data() {
    return {
      loading: true,
      config: null,
      // config2: null,
      // payRule: 0,
    }
  },
  // watch: {
  // payRule(val) {
  //   if (this.config2) {
  //     let fee_config = this.config2.midway_member_fee_config;
  //     if (val === 0) {
  //       fee_config.full_fee = 1;
  //       fee_config.full_month = 0;
  //       fee_config.full_year = 0;
  //     } else if (val === 1) {
  //       fee_config.full_fee = 0;
  //       fee_config.full_month = 0;
  //       fee_config.full_year = 1;
  //     } else if (val === 2) {
  //       fee_config.full_fee = 0;
  //       fee_config.full_month = 1;
  //       fee_config.full_year = 0;
  //     }
  //   }
  // },
  // },
  created() {
    this.getConfig();
  },
  methods: {
    // handleMinus() {
    //   if (this.config2.fee_deadline < 1) {
    //     this.config2.fee_deadline = 1;
    //   }
    // },
    handleMinusTerm() {
      if (this.config.name < 1) {
        this.config.name = 1;
      }
    },
    getConfig() {
      getTermConfig()
        .then((res) => {
          this.config = res.data;
          // getMemberConfig().then(res => {
          //   this.config2 = res.data;
          this.loading = false;
          // }).catch(err => {
          //   this.loading = false;
          // })
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    saveConfig() {
      this.loading = true;
      saveTermConfig({
        config: this.config,
      })
        .then((res) => {
          // saveMemberConfig({
          //   config: this.config2
          // }).then(res => {
          this.$message.success(res.msg);
          this.loading = false;
          // }).catch(err => {
          //   this.loading = false;
          // })
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.term-config {
  .list-item {
    min-height: 100px;
  }

  .config-item + .config-item {
    margin-top: 79px;
  }

  .config-item {
    padding-top: 20px;
    .config-title {
      padding-left: 8px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      border-left: 3px #3479ef solid;
    }
    .config-content {
      margin-top: 32px;
      padding-left: 11px;
      .switch-config {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .form-title {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 14px;
          margin-right: 15px;
        }
      }
      .form-tip {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
        line-height: 12px;
        span + span {
          margin-left: 4px;
        }
      }
      .suffix {
        line-height: 34px;
        margin-left: 12px;
        color: rgba(0, 0, 0, 0.85);
      }

      .el-input-number {
        max-width: 140px;
      }
    }
  }

  .flex-col {
    .el-radio {
      line-height: 32px;
    }
  }

  input[type="number"],
  ::v-deep input[type="number"] {
    text-align: center;
  }
}
</style>
